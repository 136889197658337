import { Box, Divider, Flex } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  RadioGroup,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { HasAccess } from "@intentsify/authorization/dist/react";
import { TalToolsTypes } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { Button, Select } from "components";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";
import { talToolsUpload } from "../../../../../../../upload/talToolsUpload";
import { useTalPreparationToolsFiles } from "../../../hooks/useTalPreparationToolsFiles";
import { useBusinessDataAppend } from "../hooks/useBusinessDataAppend";
import { firmoOptions } from "./BusinessDataAppend.options";
import { BusinessDataAppendFiles } from "./BusinessDataAppendFiles";
import { BusinessEventsFilter } from "./BusinessEventsFilter";
import { TechnographicProducts } from "./TechnographicProducts";

const businessDataAppendSchema = z
  .object({
    fileName: z.string().nonempty(),
    firmo: z.array(z.string()),
    techno: z
      .array(
        z.object({
          id: z.number(),
          name: z.string(),
          value: z.string(),
        })
      )
      .max(10),
    businessEvents: z.array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    ),
    businessEventsPerCategory: z
      .number({
        invalid_type_error: "Must be a number between 1 and 15.",
      })
      .min(1)
      .max(15),
  })
  .refine(
    (value) =>
      value.firmo.length > 0 ||
      value.techno.length > 0 ||
      value.businessEvents.length > 0,
    {
      message:
        "At least one firmographic or technographic filter must be selected.",
    }
  );

type BusinessDataAppendFormProps = {
  lastUploadedFile: string;
};

const BusinessDataAppendForm = ({
  lastUploadedFile,
}: BusinessDataAppendFormProps) => {
  const componentColors = useComponentColors();
  const tusFiles = talToolsUpload.useFiles();
  const { mutateAsync: appendBusinessData, isLoading: isSubmitting } =
    useBusinessDataAppend();
  const { data: files, isFetching: isLoadingFiles } =
    useTalPreparationToolsFiles(TalToolsTypes.BUSINESS_DATA_APPEND);

  const { control, register, handleSubmit, reset, formState, setValue, watch } =
    useForm({
      mode: "onChange",
      defaultValues: {
        firmo: [],
        techno: [],
        businessEvents: [],
        businessEventsPerCategory: 5,
        fileName: "",
      },
      resolver: zodResolver(businessDataAppendSchema),
    });

  const uploadingFiles = tusFiles.filter(
    ({ status }) => status === "uploading"
  );

  const businessEventsValue = watch("businessEvents");

  const onSubmitHandler = handleSubmit(async (data) => {
    await appendBusinessData({
      fileName: data.fileName,
      firmos: data.firmo,
      technos: data.techno,
      businessEvents: data.businessEvents,
      businessEventsPerCategory: data.businessEventsPerCategory,
    });
    reset();
  }) as () => void;

  useEffect(() => {
    if (lastUploadedFile) {
      setValue("fileName", lastUploadedFile);
    }
  }, [lastUploadedFile, setValue]);

  return (
    <Box as="form" onSubmit={onSubmitHandler}>
      <Box>
        <Heading as="h3" size="sm" my="4">
          Uploaded Files
        </Heading>
        {Boolean(isLoadingFiles || uploadingFiles.length) && (
          <Spinner
            thickness="4px"
            speed="0.75s"
            size="md"
            {...componentColors.spinner}
          />
        )}
        {!isLoadingFiles && !files?.length && (
          <Text fontSize="sm">Uploaded files will appear here.</Text>
        )}
        {Boolean(files?.length) && (
          <Controller
            name="fileName"
            control={control}
            render={({ field }) => (
              <RadioGroup
                onChange={(e) => {
                  field.onBlur();
                  field.onChange(e);
                }}
                value={field.value}
              >
                <BusinessDataAppendFiles
                  files={files ?? []}
                  onFileDelete={(deletedFileName) => {
                    if (deletedFileName === field.value) {
                      field.onChange("");
                    }
                  }}
                />
              </RadioGroup>
            )}
          />
        )}
      </Box>

      <Heading as="h3" size="sm" my="4">
        Firmographic Append
      </Heading>
      <Box w={320} mb={2}>
        <Controller
          name="firmo"
          control={control}
          render={({ field }) => (
            <Select
              options={firmoOptions}
              isMulti
              value={firmoOptions.filter((option) =>
                field.value.some((value) => option.value.includes(value))
              )}
              onChange={(options) =>
                field.onChange(options.map((option) => option.value))
              }
              onBlur={field.onBlur}
            />
          )}
        />
      </Box>

      <Heading as="h3" size="sm" my="4">
        Technographic Append
      </Heading>
      <Flex>
        <Box w={320}>
          <Controller
            name="techno"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl isInvalid={invalid}>
                <TechnographicProducts
                  value={field.value}
                  onChange={(value) => {
                    field.onBlur();
                    field.onChange(value);
                  }}
                />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
      </Flex>

      <HasAccess to="earlyAccess.businessEvents">
        <Heading as="h3" size="sm" my="4">
          Business Events Append
        </Heading>
        <Flex>
          <Box w={320}>
            <Controller
              name="businessEvents"
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl isInvalid={invalid}>
                  <BusinessEventsFilter
                    value={field.value}
                    onChange={(value) => {
                      field.onBlur();
                      field.onChange(value);
                    }}
                  />
                  <FormErrorMessage>{error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </Box>
        </Flex>

        <FormLabel
          mt={4}
          fontSize="sm"
          fontWeight="md"
          color={componentColors.form.formLabelColor}
        >
          Number of business events per category
        </FormLabel>

        <Flex>
          <Box w={320}>
            <Controller
              name="businessEventsPerCategory"
              control={control}
              render={({ fieldState: { error, invalid } }) => (
                <FormControl isInvalid={invalid}>
                  <NumberInput
                    size="sm"
                    rounded="md"
                    max={15}
                    min={1}
                    step={1}
                    isDisabled={!isPopulatedArray(businessEventsValue)}
                  >
                    <NumberInputField
                      {...register("businessEventsPerCategory", {
                        valueAsNumber: true,
                      })}
                      type="number"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  <FormErrorMessage>{error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </Box>
        </Flex>
      </HasAccess>

      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isSubmitting}
          isDisabled={!formState.isValid}
          type="submit"
        >
          Process Domains
        </Button>
      </Flex>
    </Box>
  );
};

export { BusinessDataAppendForm };
