import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import {
  HasAccess,
  useHasAccessTo,
} from "@intentsify/authorization/dist/react";
import {
  BuyerResearchStage,
  ReviewedAsset,
  SignalType,
  SortDirection,
} from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useState } from "react";
import { ReviewedAssetColumnName } from "screens/ContentLibrary/Completed/useColumns";
import { useReviewedAssets } from "screens/ContentLibrary/Completed/useReviewedAssets";
import { useTrackTabClicked } from "tracking/useTrackTabClicked";
import { DomainSignal, FetchDataParamsWithToken } from "types";
import { SignalsMap } from "../../../CampaignDetails.types";
import {
  SignalsTable,
  getColumns,
  getColumnsSiteVisitorAnDisplay,
  getDynamic1Columns,
} from "./components";
import { BusinessEvents } from "./components/BusinessEvents/BusinessEvents";
import { Dynamic1 } from "./components/Dynamic1";
import { Leads } from "./components/Leads/Leads";
import { TopLocations } from "./components/TopLocations";

type SignalsTabsProps = {
  signalsMap: SignalsMap;
  domainSignals: DomainSignal[];
  campaignId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
  buyerResearchStage: BuyerResearchStage;
};

export const SignalsTabs = ({
  signalsMap,
  domainSignals,
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
  buyerResearchStage,
}: SignalsTabsProps) => {
  const trackTabClicked = useTrackTabClicked();
  const [reviewedAssets] = useReviewedAssetsWithParams({
    buyerResearchStage,
    campaignId,
  });

  const canAccessBusinessEvents = useHasAccessTo("earlyAccess.businessEvents");

  return domainSignals ? (
    <Tabs
      isLazy
      colorScheme="teal"
      onChange={(index) => {
        trackTabClicked({
          name: domainSignals[index]?.signal || "Top Locations",
          collocation: "Campaign details signals tabs",
        });
      }}
    >
      <TabList>
        {domainSignals.map(({ signal, signalId }) => {
          if (
            signalId === SignalType.BusinessEvent &&
            !canAccessBusinessEvents
          ) {
            return null;
          }

          return (
            <Tab key={signalId}>
              <Text fontWeight="semibold">{signal}</Text>
            </Tab>
          );
        })}

        <Tab>
          <Text fontWeight="semibold">Top Locations</Text>
        </Tab>

        <HasAccess to="contentLibrary">
          {(reviewedAssets.data?.reviewedAssets.length ?? 0) > 0 && (
            <Tab>
              <Text fontWeight="semibold">Content Recommendations</Text>
            </Tab>
          )}
        </HasAccess>
      </TabList>

      <TabPanels>
        {domainSignals.map(({ signalId }) => {
          const { isHitCountedEnabled, isScoreEnabled, isLocationEnabled } =
            signalsMap[signalId];
          const showOnlyName = !isHitCountedEnabled && !isScoreEnabled;

          const defaultSortByColumn =
            isHitCountedEnabled || isScoreEnabled ? "baseWeekScore" : "name";

          if ([SignalType.SiteVisitor, SignalType.Display].includes(signalId)) {
            return (
              <TabPanel key={signalId} paddingX={0}>
                <SignalsTable
                  columns={getColumnsSiteVisitorAnDisplay()}
                  defaultSort={[
                    {
                      id: defaultSortByColumn,
                      desc: true,
                    },
                  ]}
                  campaignId={campaignId}
                  domainId={domainId}
                  signalId={signalId}
                  weekNumber={weekNumber}
                  yearNumber={yearNumber}
                />
              </TabPanel>
            );
          }

          if (signalId === SignalType.Dynamic1) {
            return (
              <TabPanel key={signalId} paddingX={0}>
                <Dynamic1
                  columns={getDynamic1Columns()}
                  defaultSortByColumn={defaultSortByColumn}
                  campaignId={campaignId}
                  domainId={domainId}
                  signalId={signalId}
                  weekNumber={weekNumber}
                  yearNumber={yearNumber}
                />
              </TabPanel>
            );
          }

          if (signalId === SignalType.Topics) {
            return (
              <TabPanel key={signalId} paddingX={0}>
                <SignalsTable
                  columns={getColumns({
                    showOnlyName,
                    showLocation: isLocationEnabled,
                    showStage: true,
                  })}
                  defaultSort={[
                    {
                      id: "baseWeekStage",
                      desc: true,
                    },
                    {
                      id: defaultSortByColumn,
                      desc: true,
                    },
                  ]}
                  campaignId={campaignId}
                  domainId={domainId}
                  signalId={signalId}
                  weekNumber={weekNumber}
                  yearNumber={yearNumber}
                />
              </TabPanel>
            );
          }

          if (signalId === SignalType.Leads) {
            return (
              <TabPanel key={signalId} paddingX={0}>
                <Leads
                  campaignId={campaignId}
                  domainId={domainId}
                  weekNumber={weekNumber}
                  yearNumber={yearNumber}
                />
              </TabPanel>
            );
          }

          if (
            signalId === SignalType.BusinessEvent &&
            canAccessBusinessEvents
          ) {
            return (
              <TabPanel key={signalId} paddingX={0}>
                <BusinessEvents
                  campaignId={campaignId}
                  domainId={domainId}
                  weekNumber={weekNumber}
                  yearNumber={yearNumber}
                />
              </TabPanel>
            );
          } else if (
            signalId === SignalType.BusinessEvent &&
            !canAccessBusinessEvents
          ) {
            return null;
          }

          return (
            <TabPanel key={signalId} paddingX={0}>
              <SignalsTable
                columns={getColumns({
                  showOnlyName,
                  // BUSINESS LOGIC: hide location when Bambora
                  // https://intentsify.atlassian.net/browse/IP-1572
                  // https://intentsify.atlassian.net/browse/IP-1607
                  showLocation: isLocationEnabled,
                })}
                defaultSort={[
                  {
                    id: defaultSortByColumn,
                    desc: true,
                  },
                ]}
                campaignId={campaignId}
                domainId={domainId}
                signalId={signalId}
                weekNumber={weekNumber}
                yearNumber={yearNumber}
              />
            </TabPanel>
          );
        })}

        <TabPanel paddingX={0}>
          <TopLocations
            campaignId={campaignId}
            domainId={domainId}
            weekNumber={weekNumber}
            yearNumber={yearNumber}
          />
        </TabPanel>

        <TabPanel paddingX={0}>
          <CampaignAssets
            campaignId={campaignId}
            buyerResearchStage={buyerResearchStage}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  ) : null;
};

const columns: ColumnDef<ReviewedAsset>[] = [
  {
    header: "Name",
    accessorKey: "name",
    cell: ({ row }) => {
      return (
        <ReviewedAssetColumnName
          name={row.original.name}
          url={row.original.downloadUrl}
        />
      );
    },
  },
  {
    header: "Content type",
    accessorKey: "contentType",
    cell: ({ row }) => {
      return <Text>{row.original.contentType}</Text>;
    },
  },
  {
    header: "Preferred Tactic",
    accessorKey: "preferredChannel",
    cell: ({ row }) => {
      return <Text>{row.original.preferredChannel}</Text>;
    },
  },
];

function useReviewedAssetsWithParams(data: {
  campaignId: number;
  buyerResearchStage: BuyerResearchStage;
}) {
  const [params, setParams] = useState<
    FetchDataParamsWithToken<keyof ReviewedAsset>
  >({ pageToken: "", order: SortDirection.DESC, order_by: "updatedAt" });
  const reviewedAssets = useReviewedAssets({
    ...params,
    campaignIds: [data.campaignId],
    buyerResearchStages: [data.buyerResearchStage],
  });

  return [reviewedAssets, setParams] as const;
}

function CampaignAssets(props: {
  campaignId: number;
  buyerResearchStage: BuyerResearchStage;
}) {
  const [reviewedAssets, setParams] = useReviewedAssetsWithParams({
    campaignId: props.campaignId,
    buyerResearchStage: props.buyerResearchStage,
  });

  return (
    <Table
      variant="intentsifyPlain"
      defaultSort={[{ id: "updatedAt", desc: true }]}
      columns={columns}
      data={reviewedAssets.data?.reviewedAssets ?? []}
      paginationType={PaginationType.TOKEN}
      nextPageToken={reviewedAssets.data?.nextPageToken}
      isFetching={reviewedAssets.isFetching}
      onFetchDataChange={setParams}
      uniqueKeyAccessor={(item) => item?.id}
    />
  );
}
