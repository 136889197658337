import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AutodiscoveryKeyword,
  AutodiscoveryProduct,
  AutodiscoveryTopic,
  AutodiscoveryUrl,
  CampaignAutodiscoveryStatus,
  Dynamic1Status,
} from "@intentsify/types";
import { Button, DynamicInput, useDynamicInput } from "components";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiSettings } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useComponentColors } from "theme";
import { useDeepEffect } from "utils";
import { z } from "zod";
import { isMutatingAutodiscoveryFilesAtom } from "../../../KeywordsAndTopicsStep.state";
import { useAutodiscovery } from "./Autodiscovery.hooks";
import { AutodiscoveryProgress } from "./components";
import { AutodiscoveryFiles } from "./components/AutodiscoveryFiles";
import { useGetAutodiscoveryFiles } from "./components/AutodiscoveryFiles/AutodiscoveryFiles.hooks";
import { AutodiscoveryResults } from "./components/AutodiscoveryResults";
import { Dynamic1Modal } from "./components/Dynamic1Modal";

const nullishValidationSchema = z.object({
  description: z.string().max(32768).nullish(),
  urls: z.array(z.string().url()).nullish(),
});

const requiredValidationSchema = z.object({
  description: z.string().min(3).max(32768),
  urls: z.array(z.string().url()).min(1).nonempty(),
});

type AutodiscoveryInputs =
  | z.infer<typeof nullishValidationSchema>
  | z.infer<typeof requiredValidationSchema>;

type AutodiscoveryProps = {
  autodiscoveryStatus: CampaignAutodiscoveryStatus;
  autodiscoveryDescription: string;
  autodiscoveryUrls: AutodiscoveryUrl[];
  autodiscoveryFiles: AutodiscoveryUrl[];
  autodiscoveryKeywords: AutodiscoveryKeyword[];
  autodiscoveryTopics: AutodiscoveryTopic[];
  autodiscoveryProducts: AutodiscoveryProduct[];
  dynamicOneStatus: Dynamic1Status;
};

const Autodiscovery = ({
  autodiscoveryStatus,
  autodiscoveryDescription,
  autodiscoveryUrls,
  autodiscoveryKeywords,
  autodiscoveryTopics,
  autodiscoveryProducts,
  autodiscoveryFiles,
  dynamicOneStatus,
}: AutodiscoveryProps) => {
  const { campaignId } = useParams<"campaignId">();

  if (!campaignId) {
    throw new Error("404");
  }

  const {
    isOpen: isD1ModalModalOpen,
    onOpen: onD1ModalOpen,
    onClose: onD1ModalClose,
  } = useDisclosure();

  const {
    processAutodiscovery,
    isProcessing,
    isProcessingStarting,
    autodiscoveryProgress,
    disableCampaignDynamic1,
    isDynamic1DisablingLoading,
  } = useAutodiscovery(campaignId);

  const componentColors = useComponentColors();

  const isMutatingAutodiscoveryFiles = useRecoilValue(
    isMutatingAutodiscoveryFilesAtom
  );

  const campaignAutodiscoveryFiles =
    useGetAutodiscoveryFiles(autodiscoveryFiles);

  const {
    items: selectedUrls,
    addItem: addUrl,
    removeItem: removeUrl,
  } = useDynamicInput(autodiscoveryUrls?.map(({ url }) => url) || []);

  const validationSchema = useMemo(() => {
    if (campaignAutodiscoveryFiles.length > 0) {
      return nullishValidationSchema;
    }

    return requiredValidationSchema;
  }, [campaignAutodiscoveryFiles.length]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm<AutodiscoveryInputs>({
    mode: "onBlur",
    resolver: zodResolver(validationSchema),
  });

  useDeepEffect(() => {
    setValue("description", autodiscoveryDescription);
    setValue(
      "urls",
      autodiscoveryUrls.map((u) => u.url)
    );
  }, [autodiscoveryDescription, autodiscoveryUrls, setValue]);

  const onProcess = (values: AutodiscoveryInputs) => {
    processAutodiscovery({
      campaignId,
      description: values.description,
      urls: values.urls,
    });
  };

  const onSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
  };

  return (
    <>
      <AutodiscoveryFiles
        campaignId={campaignId}
        files={campaignAutodiscoveryFiles}
      />
      <chakra.form borderTopRadius={4} onSubmit={onSubmit}>
        <Stack p={4} bg={componentColors.form.stackBg} spacing={4}>
          <FormControl
            as={GridItem}
            colSpan={[6, 3]}
            isInvalid={!!errors?.urls}
          >
            <Controller
              name="urls"
              control={control}
              render={({ field: { onChange } }) => (
                <DynamicInput
                  label="URLs"
                  items={selectedUrls}
                  addItem={addUrl}
                  removeItem={removeUrl}
                  inputType={"text"}
                  onChange={(val) => onChange(val)}
                  placeholder="Please a type url address here"
                />
              )}
            />
            <FormErrorMessage>
              {Array.isArray(errors?.urls)
                ? errors.urls.length > 0 && errors.urls[0].message
                : (errors?.urls as any)?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            as={GridItem}
            colSpan={[6, 3]}
            isInvalid={!!errors?.description?.message}
          >
            <FormLabel
              fontSize="sm"
              fontWeight="semibold"
              color={componentColors.form.formLabelColor}
            >
              Campaign Value Proposition Description
            </FormLabel>
            <Textarea
              {...register("description")}
              placeholder="Please fill campaign description here"
              size="sm"
            />
            <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
          </FormControl>
          <Heading as="h2" fontSize="sm">
            Note:
          </Heading>
          <Text fontSize="xs" mt="8px!important">
            To configure Dynamic1 for your campaign, you must provide either URL
            and description or upload a PDF and click “Process” below. Dynamic1
            will then automatically process for your campaign within 48 hours.
            You may remove Dynamic1 from your campaign at any time by clicking
            the "Disable" button to the left of the screen.
          </Text>
          <HStack
            py={3}
            textAlign="right"
            borderBottomRadius={4}
            justifyContent="space-between"
          >
            <Button
              p={4}
              size={"sm"}
              onClick={() => {
                disableCampaignDynamic1({ campaignId });
              }}
              isDisabled={
                [
                  CampaignAutodiscoveryStatus.ERROR,
                  CampaignAutodiscoveryStatus.STATE_UNSPECIFIED,
                ].includes(autodiscoveryStatus) ||
                [Dynamic1Status.Unspecified, Dynamic1Status.Disabled].includes(
                  dynamicOneStatus
                ) ||
                process.env.VITE_NODE_ENV !== "production"
              }
              isLoading={isDynamic1DisablingLoading}
            >
              Disable Dynamic1
            </Button>
            <Button
              isLoading={isProcessingStarting}
              onClick={() => {
                if (
                  ![
                    Dynamic1Status.Unspecified,
                    Dynamic1Status.Disabled,
                  ].includes(dynamicOneStatus) &&
                  isValid
                ) {
                  onD1ModalOpen();
                } else {
                  handleSubmit((data) => onProcess(data))();
                }
              }}
              isDisabled={
                isProcessing ||
                isMutatingAutodiscoveryFiles ||
                process.env.VITE_NODE_ENV !== "production"
              }
            >
              <HStack>
                <FiSettings />
                <Text>
                  {autodiscoveryStatus === CampaignAutodiscoveryStatus.PROCESSED
                    ? "Reprocess"
                    : "Process"}
                </Text>
              </HStack>
            </Button>
          </HStack>
          {isProcessing && (
            <AutodiscoveryProgress
              autodiscoveryProgress={autodiscoveryProgress}
            />
          )}
          {!isProcessing && (
            <AutodiscoveryResults
              campaignId={campaignId}
              keywords={autodiscoveryKeywords}
              topics={autodiscoveryTopics}
              products={autodiscoveryProducts}
              urls={autodiscoveryUrls}
              files={autodiscoveryFiles}
            />
          )}
        </Stack>
      </chakra.form>
      <Dynamic1Modal
        isOpen={isD1ModalModalOpen}
        onOk={() => {
          onD1ModalClose();
          handleSubmit((data) => onProcess(data))();
        }}
      />
    </>
  );
};

export { Autodiscovery };
